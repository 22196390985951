import React, { Component } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import pageGetter from '../Middleware/pageGetter';
import brocolli from '../assets/images/broccoli.png';
import garlic from '../assets/images/garlic.png';
import groupMainAssets from '../assets/images/group-main-assets.png';
import groupMainPlanche from '../assets/images/group-main-planche.png';
import group2Main from '../assets/images/group2-main.png';
import mushroom from '../assets/images/mushroom.png';
import onion from '../assets/images/onion.png';
import thyme from '../assets/images/thyme.png';


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('landing').then((data) => {
      this.setState({ data });
    });
  }

  handleImage(id) {
    const { data } = this.state;
    return data[`logo_image_${id}`] ? data[`logo_image_${id}`].url : '';
  }

  scrollToComponent() {
    const { scrollButton } = this.props;
    window.scrollTo({ behavior: 'smooth', top: scrollButton.current.offsetTop });
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return <div />;
    }
    return (
      <section className="home section__container">
        <div className="section__background">
          <div className="image__container">
            <img
              className="image__main"
              src={groupMainPlanche}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-left"
            />
            <img
              className="image__main-assets d-none d-lg-block"
              src={groupMainAssets}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-down-left"
            />
            <img
              className="image__onion d-none d-lg-block"
              src={onion}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up-left"
            />
            <img
              className="image__garlic d-md-none"
              src={garlic}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up-left"
            />
            <img
              className="image__garlic d-none d-md-block"
              src={garlic}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up-left"
            />
            {/*}
            <img
              className="image__main-leg d-md-none d-lg-block"
              src={group2Main}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up"
            />
    { */}
            <img
              className="image__mushroom d-md-none d-lg-block"
              src={mushroom}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-down-left"
            />
            <img
              className="image__broccoli"
              src={brocolli}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up"
            />
            <img
              className="image__thyme d-md-none"
              src={thyme}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up"
            />
            <img
              className="image__thyme d-none d-md-block"
              src={thyme}
              alt="cuisine plancher couteau légumes"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos="custom-fade-up"
            />
          </div>
        </div>
        <Container className="section__part">
          <Row className="my-1">
            <Col lg={7}>
              <div
                className="section__title"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos="custom-fade-left"
              >
                <h1 className="color--black">{data.section_title_1}</h1>
              </div>
            </Col>
          </Row>
          <Row className="my-1">
            <Col lg={7}>
              <div
                className="section__body"
                data-aos-delay="500"
                data-aos-duration="1000"
                data-aos="custom-fade-left"
              >
                <p dangerouslySetInnerHTML={{ __html: data.section_description_1 }} />
              </div>
            </Col>
          </Row>
          <Row className="my-1">
            <Col lg={7}>
              <div
                className="section__button"
                data-aos-delay="500"
                data-aos-duration="1000"
                data-aos="custom-fade-left"
              >
                <Button
                  type="button"
                  className="btn-custom--green"
                  onClick={() => {
                    return this.scrollToComponent();
                  }}
                >
                  EN SAVOIR PLUS
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="section__part">
          <Row>
            <Col sm={4}>
              <div className="speech__container d-flex flex-column justify-content-start">
                <div className="speech__logo">
                  <img src={this.handleImage(1)} alt="box" />
                </div>
                <div className="speech__title">
                  <h2 className="color--black">{data.logo_title_1}</h2>
                </div>
                <div className="speech__description">
                  <p className="p--small">{data.logo_description_1}</p>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="speech__container d-flex flex-column justify-content-start">
                <div className="speech__logo">
                  <img src={this.handleImage(2)} alt="bell" />
                </div>
                <h2 className="color--black">
                  <div className="speech__title">{data.logo_title_2}</div>
                </h2>
                <div className="speech__description">
                  <p className="p--small">
                    {data.logo_description_2}
                    {' '}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="speech__container d-flex flex-column justify-content-start">
                <div className="speech__logo">
                  <img src={this.handleImage(3)} alt="list" />
                </div>
                <h2 className="color--black">
                  <div className="speech__title">{data.logo_title_3}</div>
                </h2>
                <div className="speech__description">
                  <p className="p--small">{data.logo_description_3}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
