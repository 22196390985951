import React, { Component } from 'react';
import pageGetter from '../Middleware/pageGetter';


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('landing').then((data) => {
      this.setState({ data });
    });
  }

  handleImage(id) {
    const { data } = this.state;
    return data[id] ? data[id].url : '';
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return <div />;
    }
    return (
      <header className="header__main">
        <div className="header__container d-none d-md-block">
          <div className="header__logo">
            <img src={this.handleImage('brand_logo_header')} alt="logo-brand" />
          </div>
        </div>
        <div className="header__container mobile d-md-none d-flex w-100 justify-content-center">
          <div className="header__logo">
            <img src={this.handleImage('brand_logo_header')} alt="logo-brand" />
          </div>
        </div>
      </header>
    );
  }
}
