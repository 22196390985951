import React, { Component } from 'react';
import {
  Container, Row, Col, FormControl, InputGroup, Button,
} from 'react-bootstrap';
import axios from 'axios';
import qs from 'qs';
import alert from '../assets/icon/warning.svg';
import pageGetter from '../Middleware/pageGetter';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      email: '',
      emailValidation: null,
      formResponseStatus: null,
    };
  }

  componentDidMount() {
    pageGetter('landing').then((data) => {
      this.setState({ data });
    });
  }

  emailValidation() {
    const { emailValidation } = this.state;
    switch (emailValidation) {
      case null:
        return null;
      case true:
        return 'valid';
      case false:
        return 'invalid';
      default:
        return null;
    }
  }

  formResponseStatus() {
    const { formResponseStatus } = this.state;
    switch (formResponseStatus) {
      case null:
        return null;
      case 200:
        return 'success';
      default:
        return 'error';
    }
  }

  handleEmailChange(e) {
    const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    const { value } = e.target;
    this.setState({ email: value });
    if (value.length === 0) {
      this.setState({ emailValidation: null });
    } else {
      this.setState({ emailValidation: regex.test(value) });
    }
  }

  submitSubscription(e) {
    const {
      email,
    } = this.state;
    let isEnterButton = false;
    let isClickSubmit = false;
    switch (e.type) {
      case 'keydown':
        e.which === 13 ? (isEnterButton = true) : (isEnterButton = false);
        break;
      case 'click':
        isClickSubmit = true;
        break;
      default:
        isEnterButton = false;
        isClickSubmit = false;
        break;
    }
    if (isEnterButton || isClickSubmit) {
      const data = {
        email,
      };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: '/form-contact.php',
      };
      // EMAIL
      axios(options)
        .then((res) => {
          this.setState({ formResponseStatus: res.status });
        });
      // SLACK
      axios.post('https://hooks.slack.com/services/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', JSON.stringify({ text: data.email }), {
        withCredentials: false,
        transformRequest: [
          (slackData, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.post['Content-Type'];
            return slackData;
          },
        ],
      });
    }
  }

  successMessage() {
    const { formResponseStatus } = this.state;
    switch (formResponseStatus) {
      case null:
        return "S'INSCRIRE";
      case 200:
        return 'Merci !';
      default:
        return 'Erreur, votre adresse mail a déjà été saisie';
    }
  }

  render() {
    const { scrollButton } = this.props;
    const { data, email, emailValidation } = this.state;
    if (!data) {
      return <div />;
    }
    return (
      <section className="signup section__container" ref={scrollButton}>
        <Container>
          <Row className="justify-content-center my-1">
            <Col lg={6}>
              <div className="section__title">
                <h1 className="color--black text-center">{data.section_title_5}</h1>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-1">
            <Col lg={8}>
              <div className="section__body">
                <p
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: data.section_description_5,
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-1">
            <Col lg={6}>
              <div className="section__form">
                <InputGroup className={[this.emailValidation(), 'mb-3 js--email-control']}>
                  <FormControl
                    className="d-none d-sm-block"
                    placeholder="Votre email (zéro spam c'est promis)"
                    aria-label="Votre email (zéro spam c'est promis)"
                    aria-describedby="basic-addon2"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      return this.handleEmailChange(e);
                    }}
                    onKeyDown={(e) => {
                      return this.submitSubscription(e);
                    }}
                  />
                  <FormControl
                    className="d-sm-none js--email-control"
                    placeholder="Votre email"
                    aria-label="Votre email"
                    aria-describedby="basic-addon2"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      return this.handleEmailChange(e);
                    }}
                    onKeyDown={(e) => {
                      return this.submitSubscription(e);
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      type="button"
                      className={['btn--custom btn-custom--labeled']}
                      onClick={(e) => {
                        return this.submitSubscription(e);
                      }}
                      disabled={!emailValidation}
                    >
                      {this.successMessage()}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-1">
            <Col>
              <div className="section__alert">
                <Row
                  className={[this.emailValidation(), 'js--alert-control justify-content-center']}
                >
                  <img className="icon--alert mr-3" src={alert} alt="alert icon" />
                  <p className="text-center  p--small form--alert">
                    L'email renseigné n'est pas valable
                  </p>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
