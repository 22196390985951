import React, { Component } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Parallax from 'react-rellax';
import pageGetter from '../Middleware/pageGetter';
import background from '../assets/images/background.png';


export default class Recette extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('landing').then((data) => {
      this.setState({ data });
    });
  }

  scrollToComponent() {
    const { scrollButton } = this.props;
    window.scrollTo({
      behavior: 'smooth',
      top: scrollButton.current.offsetTop,
    });
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return <div />;
    }
    return (
      <section className="recette section__container">
        <div className="section__background">
          <div className="image__container">
            <Parallax
              as="img"
              speed={-1}
              src={background}
              alt="assiettes plats pates"
            />
          </div>
        </div>
        <Container>
          <Row className="my-1">
            <Col lg={7} className="mb-3">
              <Parallax as="div" speed={1} className="section__title">
                <h1 className="color--black">{data.section_title_3}</h1>
              </Parallax>
            </Col>
          </Row>
          <Row className="my-1">
            <Col lg={7}>
              <Parallax as="div" speed={0.5} className="section__body">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.section_description_3,
                  }}
                />
              </Parallax>
            </Col>
          </Row>
          <Row className="my-1">
            <Col lg={7}>
              <div className="section__button">
                <Button
                  type="button"
                  className="btn-custom--green"
                  onClick={() => {
                    return this.scrollToComponent();
                  }}
                >
                  DÉCOUVRIR LES RECETTES
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
