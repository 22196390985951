import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import pageGetter from '../Middleware/pageGetter';


export default class Conviction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('landing').then((data) => {
      this.setState({ data });
    });
  }

  handleImage(id) {
    const { data } = this.state;
    return data[`logo_image_${id}`] ? data[`logo_image_${id}`].url : '';
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return <div />;
    }
    return (
      <section className="conviction section__container">
        <Container className="justify-content-center">
          <Row className="justify-content-center my-1">
            <Col>
              <div className="section__title">
                <h1 className="color--black text-center d-none d-sm-block">
                  {data.section_title_4}
                </h1>
                <h1 className="color--black d-sm-none">{data.section_title_4}</h1>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={6}>
              <div className="section__subtitle">
                <h2 className="color--black">{data.section_title_4_left}</h2>
              </div>
              <div className="section__subbody">
                <p
                  className="p--small"
                  dangerouslySetInnerHTML={{ __html: data.section_description_4_left }}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="section__subtitle">
                <h2 className="color--black">{data.section_title_4_right}</h2>
              </div>
              <div className="section__subbody">
                <p
                  className="p--small"
                  dangerouslySetInnerHTML={{ __html: data.section_description_4_right }}
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center my-1">
            <Col className="d-flex justify-content-end">
              <div className="image__container " data-aos-duration="1000" data-aos="fade-up-right">
                <img src={this.handleImage(4)} alt="demeter" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              <div className="image__container" data-aos-duration="1500" data-aos="fade-up">
                <img src={this.handleImage(5)} alt="ensemble" />
              </div>
            </Col>
            <Col
              className="d-flex justify-content-start"
              data-aos-duration="1000"
              data-aos="fade-up-left"
            >
              <div className="image__container ">
                <img src={this.handleImage(6)} alt="ab bio" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
