import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './Partials/Header';
import Home from './Components/Home';
//  V2 => import Application from './Components/Application';
import Recette from './Components/Recette';
import Conviction from './Components/Conviction';
import Signup from './Components/Signup';
import './styles/app.scss';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.scrollButton = React.createRef();
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <main className="App">
        <Header />
        <Home scrollButton={this.scrollButton} />
        <Recette scrollButton={this.scrollButton} />
        <Conviction />
        <Signup scrollButton={this.scrollButton} />
      </main>
    );
  }
}
